import {css} from 'styled-components';
import {sizePx} from './helpers/size';
import colors from './constants/colors';

const none = css`
  &:focus,
  &:focus-within {
    outline: none;
  }
`;

const brand = (shadowToInsert: string | undefined = undefined) => css`
  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: none;
    border-color: transparent;
    box-shadow: ${sizePx(0)} ${sizePx(0)} ${sizePx(0)} ${sizePx(1)} ${colors.red75}
      ${!!shadowToInsert && `,${shadowToInsert}`};
  }
`;
export default {
  none,
  brand,
};
