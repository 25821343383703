import styled, {css} from 'styled-components';
import colors from '@/theme/constants/colors';
import container from '@/theme/constants/container';
import typography from '@/theme/typography';
import zIndex from '@/theme/constants/zIndex';
import breakpoints from '@/theme/constants/breakpoints';
import hidden from '@/theme/hidden';
import {StyledDropdownProps, StyledListItemProps} from './typings';
import pxToRem from '@/theme/helpers/pxToRem';
import {sizePx} from '@/theme/helpers/size';
import {StyledSocialIcons} from '@/molecules/SocialIcons/styles';
import {StyledProfileMenuContainer} from '@/molecules/Fragments/Menu/Navigation/Profile/styles';

const navigationHeight = 74;

export const StyledDesktopNavigation = styled.div`
  display: none;
  @media only screen and ${breakpoints.device.l} {
    height: ${navigationHeight}px;
    width: 100%;
    background: ${colors.grey25};
    display: flex;
    justify-content: center;
    position: sticky;
    z-index: ${zIndex.fixed}; //Fixed position to overflow any possible sticky elements on the page
    top: 0;
  }
`;

export const StyledListItem = styled.li<StyledListItemProps>`
  ${(props) => (props.$bold ? typography.Subtitle1 : typography.Body1)}
  ${(props) =>
    props.$hasDropdown &&
    css`
      ${!props.$hasWideDropdown &&
      css`
        position: relative;
        display: inline-flex;
        justify-content: center;
      `}

      > span,
      > a {
        display: block;
        position: relative;
      }

      &:hover {
        > span::after,
        > a::after {
          content: '';
          display: block;
          height: 0;
          width: 0;
          border: 12px inset transparent;
          border-bottom: 12px solid #fff;
          position: absolute;
          bottom: 0;
          left: 50%;
          margin-left: -12px;
          z-index: 1; // To put triangle above shadow
        }

        > ${StyledDropdown} {
          display: flex;
        }
      }
    `}
  ${(props) =>
    props.$justifyRight &&
    css`
      margin-left: auto !important;
    `}
  ${(props) =>
    props.$has === 'profileIcon' &&
    css`
      > span {
        padding: 0 !important;
      }
    `}
`;

const sharedDropdownStyling = css`
  display: none;
  background: ${colors.white};
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
`;

const wideDropdown = css`
  ${sharedDropdownStyling}
  justify-content: center;
  width: 100%;
  left: 0;
  right: 0;
  top: auto;

  > div {
    max-width: ${container.maxWidth};
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: ${sizePx(10)} 0;

    > figure {
      margin: 0;
      max-width: 275px;
      min-width: 220px;
      flex-grow: 1;

      &:not(:first-child) {
        margin-left: ${sizePx(5)};
      }

      > figcaption {
        ${typography.Art}
        height: ${pxToRem(48)};
        padding: 0 24px;
        line-height: ${pxToRem(48)} !important;
      }

      > ul {
        list-style: none;
        padding: 0 24px;

        > ${StyledListItem} {
          font-size: ${pxToRem(14)};
          display: flex;
          align-items: center;
          margin: ${sizePx(5)} 0;

          > a {
            display: flex;
            align-items: center;
            flex-grow: 1;
            cursor: pointer;

            &:hover {
              color: ${colors.darkBlue50};
            }
          }

          ${StyledSocialIcons} {
            margin-top: 15px;
          }
        }
      }
    }
  }
`;

const smallDropdown = css`
  ${sharedDropdownStyling}
  width: max-content;
  top: 100%;
  padding: 0;

  > figure {
    margin: 0;

    > ul {
      list-style: none;
      padding: 0;

      > ${StyledListItem} {
        display: flex;
        align-items: center;

        &:hover {
          background: ${colors.grey25};
        }

        > a,
        > span {
          height: 48px;
          padding: 0 24px;
          display: flex;
          align-items: center;
          flex-grow: 1;
          cursor: pointer;

          &:hover {
            color: ${colors.darkBlue100};
          }
        }
      }
    }
  }
`;

export const StyledDropdown = styled.div<StyledDropdownProps>`
  ${(props) => (props.$wide ? wideDropdown : smallDropdown)}
`;

export const StyledNav = styled.nav`
  max-width: ${container.maxWidth};
  width: 100%;
  height: ${navigationHeight}px;
  padding: ${container.padding};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > h2 {
    ${hidden.outlineHeading}
  }

  > figure {
    margin: 0;
    flex-grow: 1;
    height: 100%;

    > ul {
      list-style: none;
      display: flex;
      flex-direction: row;
      margin: 0;
      padding: 0;
      width: 100%;

      > ${StyledListItem} {
        padding: 0;
        margin: 0;
        line-height: ${navigationHeight}px;
        height: ${navigationHeight}px;

        &:first-child {
          > a {
            padding-bottom: 8px;
            margin-right: 30px;
          }
        }

        > span,
        > a {
          padding: 0 10px;
        }

        > span,
        > a {
          cursor: pointer;
          text-decoration: none;
          color: ${colors.darkBlue100};
          display: inline-flex;
          align-items: center;
          height: 100%;

          > ${StyledProfileMenuContainer} {
            height: 100%;
          }

          &:hover {
            color: ${colors.red100};
          }
        }
      }
    }
  }
`;

export const StyledDivider = styled.div`
  height: 1px;
  background: ${colors.grey50};
  width: 100%;
`;
